import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button, Form, Input, Typography, theme, Divider, Spin } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { useAuth } from '../../../contexts/AuthContext';

import Image from '../../../assets/logo.svg';

const { Text } = Typography;

type FormProps = { username: string; password: string };

const Login = () => {
  const { isAuthenticated, signIn } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [state] = useState(location.state || {});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(state?.from || '/dashboard');
    }
  }, [isAuthenticated, navigate, state]);

  const {
    token: { colorPrimary, colorBgContainer, colorTextDescription },
  } = theme.useToken();

  const onFinish = async (values: FormProps) => {
    setIsLoading(true);
    await signIn(values).catch(() => {
      setIsLoading(false);
    });
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor:
          colorBgContainer === '#141414' ? colorBgContainer : '#f0f0f0',
      }}
    >
      <div
        style={{
          padding: 8,
          minHeight: 500,
          minWidth: 400,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 16,
          }}
        >
          <img src={Image} alt="Logo Arcke" style={{ height: '44px' }} />
          <Text style={{ color: colorTextDescription, textAlign: 'center' }}>
            Central de Atendimento ao Cliente Arcke
          </Text>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 70,
          }}
        >
          <Text
            style={{
              color: '#4ECB73',
              borderBottom: '2px solid #4ECB73',
              marginBottom: '-20px',
              paddingBottom: 2,
            }}
          >
            Login
          </Text>
          <Divider />
          <Spin spinning={isLoading}>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              style={{
                width: 360,
              }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Por favor insira seu nome de usuário!',
                  },
                ]}
              >
                <Input
                  prefix={
                    <UserOutlined
                      style={{
                        color: colorPrimary,
                      }}
                    />
                  }
                  placeholder="Login de Acesso"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'Por favor insira sua senha!' },
                ]}
              >
                <Input.Password
                  prefix={
                    <LockOutlined
                      style={{
                        color: colorPrimary,
                      }}
                    />
                  }
                  placeholder="Senha"
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 20,
                  }}
                >
                  <Button
                    type="link"
                    htmlType="button"
                    style={{ fontSize: 16 }}
                  >
                    Esqueci minha senha
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{ width: 114 }}
                  >
                    Entrar
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export { Login };
