import { MenuOutlined } from '@ant-design/icons';
import { Button, Dropdown, MenuProps } from 'antd';
import { ColumnType } from 'antd/es/table';

export type ActionFunction<T> = (record: T) => MenuProps['items'];

const ActionColumn = <T,>(actions: ActionFunction<T>): ColumnType<T> => {
  return {
    title: 'Ações',
    width: 80,
    align: 'center',
    dataIndex: 'actions',
    key: 'actions',
    render: (_: T, record: T) => {
      return (
        <Dropdown
          menu={{
            items: actions(record),
          }}
          arrow
        >
          <Button
            type="text"
            icon={<MenuOutlined style={{ fontSize: '12px' }} />}
          />
        </Dropdown>
      );
    },
  };
};

export { ActionColumn };
