import { message } from 'antd';
import axios, { AxiosError, AxiosHeaders } from 'axios';

export const TOKEN_API = '@arcke-client:token';
export const STORAGE_USER = '@arcke-client:user';

const api = axios.create({ baseURL: process.env.REACT_APP_API });

interface AuthConfig extends AxiosHeaders {
  Authorization: string;
}

api.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
  } as AuthConfig;
  return config;
});

api.interceptors.response.use(undefined, (data: AxiosError<any>) => {
  if (data.response) {
    if (data.response.status === 401 && data.config?.url !== '/login') {
      window.location.replace('/login');
      localStorage.removeItem(STORAGE_USER);
      localStorage.removeItem(TOKEN_API);
    }
    if (
      data.response.status === 404 &&
      data.response.data?.code !== 'E_ROW_NOT_FOUND'
    ) {
      message.error('Rota não encontrada!');
    }
  }
  return Promise.reject(data);
});

export { api };
