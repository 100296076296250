import { useEffect, useState } from 'react';
import { useLoaderData, useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  Form,
  Input,
  Typography,
  theme,
  Divider,
  Spin,
  App,
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import { useAuth } from '../../../contexts/AuthContext';

import Image from '../../../assets/logo.svg';
import axios from 'axios';

const { Text } = Typography;

type FormProps = { username: string; password: string };

const CreateAccount = () => {
  const { message } = App.useApp();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { token }: any = useLoaderData();

  const [form] = Form.useForm();
  const [state] = useState(location.state || {});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(state?.from || '/');
    }
    if (token) {
      axios
        .get(process.env.REACT_APP_API + '/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch(() => {
          navigate(state?.from || '/');
        });
    } else {
      navigate(state?.from || '/');
    }
  }, [isAuthenticated, navigate, token, state]);

  const {
    token: { colorPrimary, colorBgContainer, colorTextDescription },
  } = theme.useToken();

  const onFinish = async (values: FormProps) => {
    await axios
      .post(process.env.REACT_APP_API + '/create-account', values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        message.success('Conta criada com sucesso!');
        navigate('/login');
      })
      .catch((e) => console.log(e));
    setIsLoading(false);
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        background: colorBgContainer,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          padding: 8,
          minHeight: 500,
          minWidth: 400,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 16,
          }}
        >
          <img src={Image} alt='Logo Arcke' style={{ height: '68px' }} />
          <Text style={{ color: colorTextDescription, textAlign: 'center' }}>
            Sistema de Abertura e Gerenciamento de Ordens de Serviço
          </Text>
        </div>
        <div>
          <Divider />
          <Spin spinning={isLoading}>
            <Form
              form={form}
              name='basic'
              layout='vertical'
              style={{ width: 350 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete='off'
            >
              <Form.Item
                label='Nome de Usuário'
                name='username'
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Por favor insira seu Nome de Usuário!',
                  },
                  {
                    pattern: /^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9_]+(?<![_.])$/,
                    message: 'Por favor insira seu Nome de Usuário válido!',
                  },
                  {
                    min: 6,
                    message:
                      'O Nome de Usuário deve ter no minimo 6 caracteres!',
                  },
                  {
                    max: 20,
                    message:
                      'O Nome de Usuário deve ter no máximo 20 caracteres!',
                  },
                ]}
              >
                <Input
                  prefix={
                    <UserOutlined
                      style={{
                        color: colorPrimary,
                      }}
                    />
                  }
                  placeholder='Nome de Usuário'
                  size='large'
                />
              </Form.Item>
              <Form.Item
                name='password'
                label='Senha'
                rules={[
                  {
                    required: true,
                    message: 'Por favor insira sua senha!',
                  },
                  {
                    min: 8,
                    message: 'A senha deve ter no minimo 8 caracteres!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  size='large'
                  placeholder='Senha'
                  prefix={
                    <LockOutlined
                      style={{
                        color: colorPrimary,
                      }}
                    />
                  }
                />
              </Form.Item>
              <Form.Item
                name='confirm'
                label='Confirma Senha'
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Por favor confime sua senha!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'A nova senha que você digitou não corresponde!'
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  size='large'
                  placeholder='Confirma Senha'
                  prefix={
                    <LockOutlined
                      style={{
                        color: colorPrimary,
                      }}
                    />
                  }
                />
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    gap: 8,
                  }}
                >
                  <Button type='primary' size='large' htmlType='submit'>
                    Criar
                  </Button>
                  <Button
                    type='link'
                    htmlType='button'
                    style={{ padding: 0 }}
                    onClick={() => navigate('/')}
                  >
                    Voltar
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>

      <Text
        style={{
          position: 'absolute',
          bottom: 12,
          color: colorTextDescription,
        }}
        strong
      >
        Arcke: Ordem de Serviço e PMOC ©2023
      </Text>
    </div>
  );
};

export { CreateAccount };
