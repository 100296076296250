import { Tag } from 'antd';

import { OrderStatus } from '../../../types';

const StatusTag = ({ children, size, color, icon }: any) => {
  return (
    <Tag
      color={color}
      icon={icon}
      style={{ width: size ? '100%' : '', textAlign: 'center' }}
    >
      {children}
    </Tag>
  );
};

export const colors = {
  CANCELLED: { name: 'CANCELADO', color: 'red-inverse' },
  REQUESTED: { name: 'SOLICITADO', color: 'lime' },
  PENDING: { name: 'PENDENTE', color: 'orange' },
  OPEN: { name: 'ABERTO', color: 'green' },
  REOPENED: { name: 'REABERTO', color: 'cyan' },
  EXECUTION: { name: 'EXECUÇÃO', color: 'blue' },
  DELAYED: { name: 'ATRASADO', color: 'red' },
  FINALIZED: { name: 'FINALIZADO', color: 'default' },
  VALIDATED: { name: 'VALIDADO', color: 'purple' },
};

interface StatusTagsProps {
  status: OrderStatus;
  size?: 'full';
}

const ServiceOrderStatusTag = ({ status, size }: StatusTagsProps) => {
  return (
    <StatusTag size={size ? size : undefined} color={colors[status]?.color}>
      {colors[status]?.name.toUpperCase()}
    </StatusTag>
  );
};

export { ServiceOrderStatusTag };
