import { FC, useState } from 'react';
import { ConfigProvider, theme, App, FloatButton } from 'antd';

import ptBR from 'antd/locale/pt_BR';

import { Routes } from './routes';

const MyApp: FC = () => {
  const [selectedTheme /* setSelectedTheme */] = useState('light');

  // const handleChangeColorMode = () => {
  //   selectedTheme === 'light'
  //     ? setSelectedTheme('dark')
  //     : setSelectedTheme('light');
  // };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#4ECB73',
          borderRadius: 2,
          wireframe: false,
          fontFamily: 'Poppins',
        },
        algorithm:
          selectedTheme === 'light'
            ? theme.defaultAlgorithm
            : theme.darkAlgorithm,
        components: {
          Pagination: {
            // itemActiveBg: '#4ECB73',
            // colorPrimary: '#fff',
          },
        },
      }}
      locale={ptBR}
    >
      <App>
        <FloatButton.BackTop tooltip="Voltar ao Topo" />
        <Routes />
      </App>
    </ConfigProvider>
  );
};

export { MyApp };
