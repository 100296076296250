import { RouteObject } from 'react-router-dom';
import { Orders } from './list';
import { loader } from '../../utils/loader';
import ServiceOrder from './order';

export const OrderRouter: RouteObject = {
  path: '/orders',
  errorElement: <h1>Route not found!</h1>,
  children: [
    {
      index: true,
      loader: loader,
      element: <Orders />,
    },
    {
      path: ':id',
      element: <ServiceOrder />,
    },
  ],
};
